import { createCipheriv, createDecipheriv, createHash, randomBytes } from 'crypto';
import { clsx } from 'clsx';
import type { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { errorMessages } from '@kota/constants';
import { env } from '~/config/env.server';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function errorMessage(data: unknown): string {
  const error = data as {
    response: {
      data: {
        errorCause: string;
        errorCode: string;
      };
    };
    cause: {
      code: string;
      message: string;
    }[];
  };

  console.log('error', error);

  if (error?.response?.data) {
    return errorMessages[error.response.data.errorCode] || (errorMessages.UnknownError as string);
  }

  return error.cause?.[0]?.message || (errorMessages.UnknownError as string);
}

export function buildSmartUrl(slug: string): string {
  const domain = process.env.NODE_ENV === 'production' ? 'autoenrolment.co.uk' : 'sandbox.autoenrolment.co.uk';

  return `https://${slug}.${domain}`;
}

type FormatCurrencyProps = {
  value: number;
  locale?: string;
  currency?: string;
};

export function formatCurrency({ value, locale = 'en-IE', currency = 'EUR' }: FormatCurrencyProps): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
}

type BuildEmailSniperUrlProps = {
  from?: string;
  provider: 'google' | 'yahoo' | 'outlook' | 'proton' | 'icloud';
};

export function buildEmailSniperUrl({ from = '@kota.io', provider }: BuildEmailSniperUrlProps): string {
  const encodedEmail = encodeURIComponent(from);

  switch (provider) {
    case 'google':
      return `https://mail.google.com/mail/u/0/#search/from%3A(${encodedEmail})+in%3Aanywhere+newer_than%3A1d`;
    case 'yahoo':
      return `https://mail.yahoo.com/d/search/keyword=from%3A${encodedEmail}`;
    case 'outlook':
      return `https://outlook.live.com/mail/0/`;
    case 'proton':
      return `https://mail.proton.me/u/0/all-mail#from=${encodedEmail}`;
    case 'icloud':
      return `https://www.icloud.com/mail/`;
  }
}

export function getNextComplianceDueDate(startDate: string): string {
  const start = new Date(startDate);
  if (isNaN(start.getTime())) throw new Error('Invalid date format. Please use YYYY-MM-DD.');

  let dueDate = new Date(start.setMonth(start.getMonth() + 4)); // 5 months, but setMonth is zero-based
  const currentDate = new Date();

  while (dueDate <= currentDate) {
    dueDate.setFullYear(dueDate.getFullYear() + 3);
  }

  return dueDate.toISOString().split('T')[0] as string;
}

export function encrypt(input: any) {
  const IV = Buffer.from(randomBytes(16));

  const encryptor = createCipheriv('aes-128-cbc', createHash('md5').update(env.CRYPTO_SECRET_KEY).digest(), IV);

  encryptor.setAutoPadding(true);
  encryptor.write(input);
  encryptor.end();

  return Buffer.concat([IV, encryptor.read()]).toString('base64');
}

export function decrypt(cipher: any) {
  const un_base64 = Buffer.from(cipher, 'base64');

  const IV = un_base64.slice(0, 16);
  const cipher_text = un_base64.slice(16);

  const decrypter = createDecipheriv('aes-128-cbc', createHash('md5').update(env.CRYPTO_SECRET_KEY).digest(), IV);

  decrypter.write(cipher_text);
  decrypter.end();

  return decrypter.read().toString('utf8');
}

export function shouldUseDemoData(searchParams: URLSearchParams, siteEnv: string): boolean {
  const isProd = siteEnv === 'production';
  if(!isProd) {
    // Allow non-prod environments to target demo data
    const isDemo = searchParams.has('demo');
    if(isDemo) {
      return true;
    }
  }
  return false;
}